import React from "react"
import { useMatch } from "@reach/router"
import NewsDetail from './news-details';
import CommonTemplate from './common-template';
import PropertyDetails from './property-details';
import CareersDetails from './careers-details-template';
import LandingPageTemplate from "./landing-page-template";
import CommonPageTemplate from "./common-page-template";
import get from 'lodash/get'
import {useStaticQuery, graphql } from 'gatsby'


export default ({data},props) => {
  
  const careersdetail = useMatch("/about/careers/:item/")
  const newsdetail = useMatch("/about/blog/:item/")
  const salematch = useMatch('/property-for-sale/:slug');
  const rentmatch = useMatch('/property-to-rent/:slug');
  const Page = data.strapiArticles
  const Modules = data.strapiArticles.Modules
  const GlobalConfig = data.strapiGlobalConfig
  const TeamMember = data.strapiArticles.Team_Members
  const TeamSlider = data.allStrapiTeams
  const JobOpenings = data.allStrapiCareers
  const OfficeList = data.allStrapiOffices
  const VideosList = data.allStrapiOurVideos

  if(salematch !=null) {
    var propertyURL = salematch.slug
    var propID = propertyURL.split('-').pop()
    propID = propID.replace("/","")
    return(
    <PropertyDetails Modules={Modules} PropertyID={propID} GlobalConfig={GlobalConfig}/>
    )
  }

  else if(rentmatch !=null) {
    var propertyURL = rentmatch.slug
    var propID = propertyURL.split('-').pop()
    propID = propID.replace("/","")
    return (
    <PropertyDetails Modules={Modules} PropertyID={propID} GlobalConfig={GlobalConfig}/>
    )
  }

  else if(careersdetail !=null) {
    var propertyURL = careersdetail.slug
    return (
    <CareersDetails detailpage={careersdetail} JobOpenings={JobOpenings} />
    )
  }
  // else if(newsdetail !=null) {
  //   return(
  //     <NewsDetail detailpage={newsdetail.item} GlobalConfig={GlobalConfig} />
  //   )
  // }
  else if(Page.Layout === "Landing_Page") {
    return(
      <LandingPageTemplate Page={Page} Modules={Modules} GlobalConfig={GlobalConfig} />
    )
  } else if(Page.Layout === "Default") {
    return(
      <CommonPageTemplate Page={Page} Modules={Modules} GlobalConfig={GlobalConfig} OfficeList={OfficeList} TeamSlider={TeamSlider} JobOpenings={JobOpenings} />
    )
  }else {
    return (
      <CommonTemplate Page={Page} Modules={Modules} GlobalConfig={GlobalConfig} TeamMember ={TeamMember} TeamSlider={TeamSlider} JobOpenings={JobOpenings} 
      OfficeList={OfficeList} VideosList={VideosList} />   
    )
  }


}


export const pageQuery = graphql`
  query DeafultQuery($id: String!) {
  strapiArticles(strapiId: {eq: $id}) {
    Alias
    id
    strapiId
    Meta_Description
    Meta_Title
    Pagename
    Layout
    Custom_CSS_Class
    Widget_Key
    Select_Popular_Search
    Modules {
      ModuleType
      ModuleSpacing  
      Banner_CTA_1_Label
      Banner_CTA_2_Label
      Banner_1_CTA_Hash
      Banner_2_CTA_Hash
      Banner_2_Custom_Link
      Banner_1_Custom_Link
      Button2_Class
      Banner_Title
      Embed_Video_URL
      Upload_Video {
        internal {
          description
        }
      }
      Show_Review
      Show_Search
      Full_Width_Display_Type
      Full_Width_Image {
          internal {
            description
          }
          childImageSharp {
            fluid(quality: 90, maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
      }
      Full_Width_Video_Embed_URL
      Full_Width_Two_Col_Title      
        Full_Width_Two_Col_Content
        Full_Width_Two_Col_CTA_Label
        Full_Width_Two_Col_CTA_URL
        Full_Width_Two_Col_Image {
          internal {
            description
          }
          childImageSharp {
            fluid(quality: 90, maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
      }
      Table_Content
      Content_Column_Left
      Content_Column_Right
      Intro_Content
      Intro_Title
      Awards_Details {
        Content
        Year
        id
      }
      Child_Pages {
        Child_Page_Label
        Child_Page_Link {
          URL
        }
        Child_Page_Custom_Link
      }
      button_list {
        button_name
        button_link {
          URL
        }
      }    
      Static_Intro_Title
      Static_Intro_Content
      Static_Strapline_Text
      Static_Banner_Title
      Static_Banner_Video_URL
      Static_Banner_Video_Caption
      Show_Breadcrumbs
      Static_Banner_Image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2048) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
      }        
      Landing_Video_Image {
        internal {
          description
        }
        childImageSharp {
          fluid(quality: 90, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Landing_Video_URL
      Video_Background_Image {
            internal {
              description
            }
            childImageSharp {
              fluid(quality: 90, maxWidth: 1800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
      }
      Banner_CTA_1_URL {
        URL
        main_parent
      }
      Banner_CTA_2_URL {
        URL
        main_parent
      }
      Valuation_Title
      Valuation_Content
      Valuation_Left_Box_Content
      Valuation_Left_Box_Cta_Label
      Valuation_Left_Box_Image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      Valuation_Left_Box_Title
      Valuation_Right_Box_Content
      Valuation_Right_Box_Title
      Valuation_Right_Box_Cta_Label
      Valuation_Left_Box_CTA_Link
      Valuation_Right_Box_CTA_Link
      Valuation_Right_Box_Image {
        publicURL
        childImageSharp {
          fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
          }
        }
      }
      History_Top {
        Title
        Caption
        Year
        history_content
        Image {
          childImageSharp {
              fixed(width: 248) {
                ...GatsbyImageSharpFixed
              }
          }

        }
      }
      History_Bottom {
       Title
        Caption
        Year
        history_content
        Image {
          childImageSharp {
            fixed(width: 248, height: 164) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      Heading
      CTA_Label
      FAQ_Items {
            Answer
            Question
            id
      }
      CTA_Link{
      id
      URL
      Link_Type
        Label
      }
      Tiles {
        CTA_Label
        Content
        Tile_Position
        Title
        Embed_Video_URL
        Tile_Image {
          childImageSharp {
              fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
              }
          }
        }
        Tile_URL {
          URL
        }
      }
      Stats {
        Caption
        Value
        Value_Type
      }
      Statistics_Title
      Section_Title
      Section_Intro
      FormList
      ModuleBGColor      
      Show_Reviews
      Show_Contact
      FormContactData {
        Label
        Link
        Type
        Icon_Type
        Show_Icon
      }
      Widget_Code
      Select_Module
      Select_Module_Title
      Select_Module_Content
      Select_Calculator
      Banner_title
      Show_Banner_Title_Spacing
      Banner_Content
      Banner_image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2048) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      Ctas {
        Label
        ctlink {
          URL
          Secondary_URL
        }
      }
    }
    Team_Members {
          id
          Designation
          Email
          Calendly_Link
          Image {
            childImageSharp {
                fluid(maxWidth: 78) {
                    ...GatsbyImageSharpFluid
                }
            }
          }
          Name
          Phone
    }
  }
  strapiGlobalConfig {
      Get_In_Touch_Content
      Send_US_Form_Content
      Elfsight_Reviews {
        Badge_Widget_Code
        Carousel_Widget_Code
        Widget_Embed_Video_URL
        Percentage_value
        Total_reviews
        Widget_Video_Bg_Image {
          internal {
            description
          }
          childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }        
      Elfsight_Instagram_Widget {
        Widget_Code
      }
      GetStartedForm {
        Section_Title
        Section_Intro
        FormList
        FormContactData {
          Label
          Link
          Type
          Icon_Type
          Show_Icon
        }
      }
  }

  allStrapiTeams {
    edges {
      node {
        strapiId
        Name
        URL
        Designation
        Email
        Phone
        Embed_Video_URL
        Calendly_Link
        Image {
          internal {
            description
          }
        }
        ggfx_results {
          transforms {  
              format
              transform
              url 
          }
          src_cftle
        }
      }
    }
  }

  allStrapiCareers {
    edges {
      node {
        Caption
        Job_Opening
        URL
        Tile_Image {
          childImageSharp {
            fluid(maxWidth: 388) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }

  allStrapiOurVideos {
    edges {
      node {
        strapiId
        Embed_Video_URL
        Title
        Select_Category
        imagetransforms {
          Video_Image_Transforms
        }
        Video_Image {         
          internal {
            description
          }
        }
        ggfx_results {
          transforms {  
            format
            transform
            url 
          }
          src_cftle
        }
      }
    }
  }
  
  allStrapiOffices {
    edges {
      node {
        strapiId
        Title
        URL
        Tile_Image{
         internal {
          description
          }
        }
        Address
        Phone_Number
        Review_Key 
        Elfsight_Widget_Code       
        ggfx_results {
          transforms {  
            format
            transform
            url 
          }
          src_cftle
        }
      }
    }
  }
  }
`
