import React from "react"
import { useMatch } from "@reach/router"
import AllNews from './news-overview-grid';
import NewsDetail from '../../templates/news-details';

const App = (props) => {
const match = useMatch('/about/blog/');
const detailmatch = useMatch("/about/blog/:item/")
  if(match != null) {
    return (
    <AllNews/>
    )
  }
  if(detailmatch != null) {
    return null;
    return (
    <NewsDetail detailpage={detailmatch.item}/>
    )
  }
}
export default App
